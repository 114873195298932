<template>
    <section class="login__bg__wrapper">
        <CContainer>
            <CRow class="justify-content-center align-items-center">
                <CCol lg="8">
                    <SignUpForm/>
                </CCol>
            </CRow>
        </CContainer>
    </section>
</template>
<script>
import { CContainer, CRow } from "@coreui/vue";
import SignUpForm from "../components/SignUpForm.vue"
export default {
  name: "LoginView",
  components:{
    SignUpForm
  }
};
</script>