<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'complaints' }">Complaints</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Add New Complaint
          </li>
        </ol>
      </nav>
    </div>
    <CRow>
      <CCol lg="12">
        <div class="add__btn text-start mt-3">
          <router-link :to="{name:'complaints'}"> Go Back</router-link>
        </div>
      </CCol>
    </CRow>
    <CRow class="justify-content-center mt-5">
      <CCol lg="10">
        <form @submit.prevent="addComplaint">
          <CRow >
            <CCol lg="12" class="mb-4">
              <div class="input-wrapper">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    v-model="compaintTitle"
                    placeholder="Enter The Title"
                    required
                  />
                  <label for="floatingInput">Enter Complaint Title</label>
                </div>
              </div>
            </CCol>
            <CCol lg="12" class="mb-4">
              <div class="input-wrapper">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    v-model="compaintText"
                    required
                  ></textarea>
                  <label>Comments</label>
                </div>
              </div>
            </CCol>
            <CCol lg="12">
              <div class="form__submit__btn">
                <button class="btn btn-primary btn-lg w-100">
                  Add Complaint
                </button>
              </div>
            </CCol>
          </CRow>
        </form>
      </CCol>
    </CRow>
  </section>
</template>

<script>
import axios from "axios";
import { CRow, CCol,  } from "@coreui/vue";
export default {
  name: "AddComplaint",
  data() {
    return {
      compaintTitle: "",
      compaintText: "",
    };
  },
  methods: {
    addComplaint() {
      const token = localStorage.getItem("token");
      const apiUrl = process.env.VUE_APP_API_URL;

      var model = {
        UserIdentity: token,
        ComplaintTitle: this.compaintTitle,
        ComplaintText: this.compaintText,
      };
      axios
        .post(
          `${apiUrl}/ComplaintRegistration`,
          model
        )
        .then((response) => {
          if (response.status === 200) {
            this.$swal.fire({
              icon: "success",
              title: "Complaint Add",
              text: "Your Complaint Has Been Added",
              showCancelButton: true,
            });
            this.compaintTitle = "";
            this.compaintText = "";
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
.input-wrapper textarea {
  height: 150px !important;
}
.input-wrapper label {
  text-transform: uppercase;
  font-weight: 400;
}
</style>
