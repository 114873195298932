<template>
  <nav class="navbar bg-light">
    <CContainer class="align-items-center">
      <a class="navbar-brand" href="#">{{ this.$route.meta.title }}</a>
      <div class="d-flex align-items-center">
        <button
          class="navbar-toggler"
          type="button"
          @click="toggleOffcanvas"
        >
          <span class="navbar-toggler-icon"></span>
          <span class="menu__lable ps-1">MENU</span>
        </button>
        <div>
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <div v-if="!loading">
                <img
                  v-if="imgPath != null"
                  :src="`${imgPath}`"
                  alt="Avtar-profile"
                  class="img-fluid avatar__profile"
                />
                <img
                  v-else
                  src="../assets/AvatarDemo.png"
                  alt="Avtar-profile"
                  class="img-fluid avatar__profile"
                />
              </div>
              <div v-if="loading">
                <div class="spinner-border text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </button>
            <ul class="dropdown-menu">
              <li>
                <div class="profile__info__wrapper">
                  <h6 class="mb-1 mt-1">{{ studentName }}</h6>
                  <p class="mb-0">{{ className }}</p>
                  <p class="mb-0">{{ schoolName }}</p>
                </div>
              </li>
              <li>
                <router-link class="dropdown-item" @click="logout()" to=""
                  >Logout</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- coreui offcanvas -->
      <COffcanvas
        placement="end"
        :visible="visible"
        @hide="toggleOffcanvas"
      >
        <COffcanvasHeader class=" justify-content-between">
          <COffcanvasTitle>
            DASHBOARD MENU
          </COffcanvasTitle>
          <CCloseButton class="text-reset" @click="toggleOffcanvas"/>
        </COffcanvasHeader>
        <COffcanvasBody>
          <div>

            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'paymentHistory' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/history.png"
                    alt="history"
                    class="me-3"
                  />Payment History
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'attendanceHistory' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/authentication.png"
                    alt="attendance"
                    class="me-3"
                  />Attendance
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'results' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/evaluation.png"
                    alt="results"
                    class="me-3"
                  />Results
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'smsAlert' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/report.png"
                    alt="alert-sms"
                    class="me-3"
                  />Alert & SMS
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'notification' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/bell.png"
                    alt="notifications"
                    class="me-3"
                  />Notifications
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'FeeBalance' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/money-report.png"
                    alt="fee-balance"
                    class="me-3"
                  />Fee Balance
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'EventCalendar' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/schedule.png"
                    alt="event-calendar"
                    class="me-3"
                  />Event & Calendar
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'complaints' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/complaint.png"
                    alt="complaint"
                    class="me-3"
                  />Complaint
                </router-link>
              </li>
              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'diary' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/diary.png"
                    alt="diary"
                    class="me-3"
                  />Diary
                </router-link>
              </li>

              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'hostel' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/hostel.png"
                    alt="diary"
                    class="me-3"
                  />Hostel
                </router-link>
              </li>

              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'StudentConsultancy' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/conversation.png"
                    alt="diary"
                    class="me-3"
                  />Student Consultancy
                </router-link>
              </li>

              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{}"
                  @click="downloadVoucher"
                >
                  <img
                    src="../assets/dashboard-icons/file.png"
                    alt="diary"
                    class="me-3"
                  />Download Voucher
                </router-link>
              </li>

              <li class="nav-item" v-if="!nullData">
                <router-link
                  class="nav-link nav-img"
                  :to="{ name: 'TimeTable' }"
                  @click="toggleOffcanvas"
                >
                  <img
                    src="../assets/dashboard-icons/timetable.png"
                    alt="diary"
                    class="me-3"
                  />Time Table
                </router-link>
              </li>

              <li class="nav-item" >
                <router-link class="nav-link nav-img" @click="logout()" to="">
                  <img
                    src="../assets/dashboard-icons/power-off.png"
                    alt="logout"
                    class="me-3"
                  />Logout
                </router-link>
              </li>
            </ul>
          </div>
        </COffcanvasBody>
      </COffcanvas>
      <!-- end coreui offcanvas -->

    </CContainer>
  </nav>
</template>

<script>
import { CCloseButton, CContainer, COffcanvas, COffcanvasBody, COffcanvasHeader, COffcanvasTitle } from "@coreui/vue";
import { ref } from 'vue';
import axios from "axios";
const visible = ref(false)
export default {
  name: "Headernav",
  props: ["studentName", "schoolName", "className", "imgPath", "loading","nullData"],
  // data() {
  //   return {
  //     pageTitle: "",
  //   };
  // },
  setup() {
    const visible = ref(false);

    const toggleOffcanvas = () => {
      visible.value = !visible.value;
    };

    return {
      visible,
      toggleOffcanvas
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("myItems");
      this.$router.push("/login");
    },
    async downloadVoucher() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const apiUrl = process.env.VUE_APP_API_URL;
      try {
        var model = {
          StudentId: studentid,
          UserIdentity: token,
        };
        const response = await axios.post(`${apiUrl}/DowloadVocher`,model);
        const voucherUrl = response.data;
        window.open(voucherUrl, '_blank');
      } catch (error) {
        console.error("Error downloading voucher:", error);
      }
    }
  },
};
</script>
