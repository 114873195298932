<template>
  <section lang="html">
    <CRow v-if="!ifNoData">
      <CCol lg="12" class="mb-3" v-for="(item, index) in myData" :key="index">
        <div class="single__notification__main__wrapper">
          <CRow>
            <CCol lg="8">
              <div class="notification__content__wrapper">
                <h3 class="mb-1">{{ item.NotificationTitle }}</h3>
                <p class="mb-0">{{ item.NotificationText }}</p>
              </div>
            </CCol>
            <CCol lg="4">
              <div
                class="notification__media__wrapper"
                v-for="(attach, index) in item.Files"
                :key="index"
              >
                <label class="d-block mb-1">Attachments</label>
                <a :href="`${attach.FileURL}`" target="_blank">{{
                  attach.FileName
                }}</a>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="ifNoData">
      <CCol lg="12">
        <div class="no__data_wrapper mt-4">
          <h2 class="mb-0">oops..! No Data Found</h2>
        </div>
      </CCol>
    </CRow>
  </section>
</template>

<script>
import axios from "axios";
import { CRow, CCol } from "@coreui/vue";
export default {
  name: "HolidaysView",
  data() {
    return {
      myData: [],
      NotificationTypeId: 3,
      ifNoData: false,
    };
  },
  created() {
    this.getResult();
  },
  methods: {
    async getResult() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.NotificationTypeId,
        };
        const response = await axios.post(
          `https://apiesm.cyberasol.com/api/Mobile/MobileGetStudentNotification`,
          model
        );
        this.myData = response.data;
        this.ifNoData = false;
        if (this.myData.length == 0) {
          this.ifNoData = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
