<template>
    <section class="payment__histroy__main__wrapper" lang="html">
        <div class="breadcrumb__main__wrapper mt-4">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                    <router-link :to="{ name: 'dashboardOption' }">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                    Student Consultancy
                    </li>
                </ol>
            </nav>
        </div>
        <CRow class="justify-content-center mt-4 pb-4">
            <h2 class="title mb-4">Student Consultancy</h2>
            
            <template v-if="myData.length">
                <div>
                  <p class="text-center">No data available</p>
                </div>
            </template>

            <template v-else>
                <CCol lg="6" v-for="(consultancy, index) in myData.StudentConsultancyList" :key="index" class="mb-3">
                <div class="col-border">
                    <div class="hostel-info-card-wrapper">
                    <CRow class="mb-2">
                        <CCol lg="4" xs="5">
                        <div class="card-label">
                            <p>University Name :</p>
                        </div>
                        </CCol>
                        <CCol lg="8" xs="7">
                        <div class="info-title">
                            <p>{{ consultancy.UniversityName }}</p>
                        </div>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4" xs="5">
                        <div class="card-label">
                            <p>Program :</p>
                        </div>
                        </CCol>
                        <CCol lg="8" xs="7">
                        <div class="info-title">
                            <p>{{ consultancy.Program }}</p>
                        </div>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4" xs="5">
                        <div class="card-label">
                            <p>Total Semester :</p>
                        </div>
                        </CCol>
                        <CCol lg="8" xs="7">
                        <div class="info-title">
                            <p>{{ consultancy.TotalSemester }}</p>
                        </div>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4" xs="5">
                        <div class="card-label">
                            <p>Fee Per Semester :</p>
                        </div>
                        </CCol>
                        <CCol lg="8" xs="7">
                        <div class="info-title">
                            <p>{{ consultancy.FeePerSemester }}</p>
                        </div>
                        </CCol>
                    </CRow>
                    <CRow class="mb-2">
                        <CCol lg="4" xs="5">
                        <div class="card-label">
                            <p>Total Program Fee :</p>
                        </div>
                        </CCol>
                        <CCol lg="8" xs="7">
                        <div class="info-title">
                            <p>{{ consultancy.TotalProgramFee }}</p>
                        </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="4" xs="5">
                        <div class="card-label">
                            <p>Duration :</p>
                        </div>
                        </CCol>
                        <CCol lg="8" xs="7">
                        <div class="info-title">
                            <p>{{ consultancy.Duration }}</p>
                        </div>
                        </CCol>
                    </CRow>
                    </div>
                </div>
                </CCol>
            </template>

        </CRow>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  import { CRow, CCol } from "@coreui/vue";
  
  export default {
    name: "StudentConsultancy",
    data() {
      return {
        myData: {
          StudentConsultancyList: [], // Default empty array
        },
      };
    },
    mounted() {
      this.getResult();
    },
    methods: {
      async getResult() {
        const token = localStorage.getItem("token");
        const studentid = this.$route.params.id;
        const apiUrl = process.env.VUE_APP_API_URL;
        try {
          var model = {
            UserIdentity: token,
            StudentId: studentid,
          };
          const response = await axios.post(
            `${apiUrl}/GetStudentConsultancy`,
            model
          );
          this.myData = response.data;
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
  </script>
  