<template>
  <section class="payment__histroy__main__wrapper">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Attendance History
          </li>
        </ol>
      </nav>
    </div>

    <CRow class="justify-content-center">
      <CCol lg="12">
        <div class="data__table__content__wrapper mt-4 mb-5">
          <div class="table-responsive">
            <table ref="mytable" class="table table-striped w-100">
              <thead>
                <tr>
                  <th >Date</th>
                  <th >Time</th>
                  <th >Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in myData" :key="index">
                  <td>
                    {{ item.AttendanceDateString }}
                  </td>
                  <td>
                    {{ formatDate(item.TimeIn) }}
                  </td>
                  <td >
                    <label :class="item.Status === 'Present' ? 'text-green' : 'text-red'"> {{ item.Status }}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CCol>
    </CRow>
  </section>
</template>

<script>
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.css";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import axios from "axios";
import $ from "jquery";
import { CRow, CCol } from "@coreui/vue";

export default {
  name: "AttendanceHistory",
  data() {
    return {
      myData: [],
    };
  },
  mounted() {
    this.getResult();
  },
  methods: {
    async getResult() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const apiUrl = process.env.VUE_APP_API_URL;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
        };
        const response = await axios.post(
          `${apiUrl}/MobileStudentAttendanceHistory`,
          model
        );
        this.myData = response.data.AttendanceHistory;
        this.initTable();
      } catch (error) {
        console.error(error);
      }
    },
    initTable() {
      this.$nextTick(() => {
        $(this.$refs.mytable).DataTable();
      });
    },
    formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const formattedTime = hours + ":" + minutes + " " + ampm;
      return formattedTime;
    },
  },
  watch: {
    users: {
      handler() {
        // Redraw the table when users changes
        this.$nextTick(() => {
          $(this.$refs.mytable).DataTable().draw();
        });
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // Destroy the table instance when the component is destroyed
    $(this.$refs.mytable).DataTable().destroy(true);
  },
};
</script>

<style>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>
