import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import SignUpView from "../views/SignupView.vue";
import StudentView from "../views/StudentsView.vue";
import DahboardView from "../views/DashbaordView.vue";
import NotFound from "../views/NotFound.vue";

// Dashboard Componenets
import PaymentHistory from "../components/dashboard/PaymentHistory.vue";
import AttendanceHistory from "../components/dashboard/AttendanceHistory";
import SMSAlert from "../components/dashboard/SmsAlert.vue";
import FeeBalance from "../components/dashboard/FeeBalance.vue";
import EventCalendar from "../components/dashboard/EventCalendar.vue";
import ExamHistory from "../components/dashboard/ExamHistory.vue";
import Diary from "../components/dashboard/Diary.vue";
import Complaints from "../components/dashboard/Complaints.vue";
import AddComplaint from "../components/dashboard/AddComplaint.vue";
import Hostel from "@/components/dashboard/Hostel.vue";
import StudentConsultancy from "@/components/dashboard/StudentConsultancy.vue";
import TimeTable from "@/components/dashboard/TimeTable.vue";
// Notifications Componenets
import Notifications from "../components/dashboard/Notifications.vue";
import Datesheet from "../components/dashboard/notifications/Datesheet.vue";
import Syllabus from "../components/dashboard/notifications/Syllabus.vue";
import Holidays from "../components/dashboard/notifications/Holidays.vue";
import General from "../components/dashboard/notifications/General.vue";
import Misconduct from "../components/dashboard/notifications/Misconduct.vue";
import Sports from "../components/dashboard/notifications/Sports.vue";
import Ptm from "../components/dashboard/notifications/Ptm.vue";

import DashboradOption from "../components/DashboardOption.vue";
import ComplainChat from "../components/dashboard/ComplainChat.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: 'Login',
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUpView,
    meta: {
      title: 'Sign Up',
    },
  },
  {
    path: "/",
    name: "students",
    // meta: { requiresAuth: true },
    component: StudentView,
    meta: {
      requiresAuth: true,
      title: 'Students',
    },
  },
  {
    path: "/dashboard/:id",
    name: "dashboard",
    // meta: { requiresAuth: true },
    redirect: { name: 'dashboardOption' },
    component: DahboardView,
    children: [
      {
        path: "",
        name: "dashboardOption",
        component: DashboradOption,
        props: true,
        meta: {
          requiresAuth: true,
          title: "Dashboard",
        },
      },
      {
        path: "paymentHistory",
        name: "paymentHistory",
        component: PaymentHistory,
        meta: {
          title: 'Payment History',
        },
      },
      {
        path: "attendanceHistory",
        name: "attendanceHistory",
        component: AttendanceHistory,
        meta: {
          title: 'Attendance History',
        },
      },
      {
        path: "smsAlert",
        name: "smsAlert",
        component: SMSAlert,
        meta: {
          title: 'Alert & SMS',
        },
      },
      {
        path: "FeeBalance",
        name: "FeeBalance",
        component: FeeBalance,
        meta: {
          title: 'Fee Balance',
        },
      },
      {
        path: "EventCalendar",
        name: "EventCalendar",
        component: EventCalendar,
        meta: {
          title: 'Event & Calendar',
        },
      },
      {
        path: "results",
        name: "results",
        component: ExamHistory,
        meta: {
          title: 'Results',
        },
      },
      {
        path: "diary",
        name: "diary",
        component: Diary,
        meta: {
          title: 'Diary',
        },
      },
      {
        path: "hostel",
        name: "hostel",
        component: Hostel,
        meta: {
          title: 'Hostel',
        },
      },
      {
        path: "StudentConsultancy",
        name: "StudentConsultancy",
        component: StudentConsultancy,
        meta: {
          title: 'Student Consultancy',
        },
      },
      {
        path: "TimeTable",
        name: "TimeTable",
        component: TimeTable,
        meta: {
          title: 'Time Table',
        },
      },
      {
        path: "notification",
        name: "notification",
        redirect: { name: 'datesheet' },
        component: Notifications,
        meta: {
          title: 'Notification',
        },
        children: [
          {
            path: '',
            name: "datesheet",
            component: Datesheet,
            meta: {
              title: 'Datesheet',
            },
          },
          {
            path: 'syllabus',
            name: "syllabus",
            component: Syllabus,
            meta: {
              title: 'Syllabus',
            },
          },
          {
            path: 'holidays',
            name: "holidays",
            component: Holidays,
            meta: {
              title: 'Holidays',
            },
          },
          {
            path: 'general',
            name: "general",
            component: General,
            meta: {
              title: 'General',
            },
          },
          {
            path: 'misconduct',
            name: "misconduct",
            component: Misconduct,
            meta: {
              title: 'Misconduct',
            },
          },
          {
            path: 'sports',
            name: "sports",
            component: Sports,
            meta: {
              title: 'Sports',
            },
          },
          {
            path: 'ptm',
            name: "ptm",
            component: Ptm,
            meta: {
              title: 'Parent Teacher Meeting',
            },
          },
        ]
      },
      {
        path: "complaints",
        name: "complaints",
        component: Complaints,
        meta: {
          title: 'Complaints',
        },
      },
      {
        path: "chat/:chatid",
        name: "chat",
        component: ComplainChat,
        meta: {
          title: 'Chat',
        },
      },
      {
        path: "addComplaint",
        name: "addComplaint",
        component: AddComplaint,
        meta: {
          title: 'Add Complaint',
        },
      },
    ],
  },
  // {
  //   path: '/:pathMatch(.*)',
  //   name: "Error",
  //   component: NotFound,
  //   meta: {
  //     title: 'Path Manager',
  //   },
  // },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  document.title = `${to.meta.title} | ESM`;
  if (requiresAuth && !localStorage.getItem("token")) {
    next("/login");
  } else {
    next();
  }
});

export default router;
