<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboardOption' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Hostel
          </li>
        </ol>
      </nav>
    </div>

    <CRow class="justify-content-center">
      <CCol lg="8">
        <h2 class="title">Hostel Information</h2>

        <!-- Show message if no data found -->
        <div v-if="!hostelData.HostelInformationList || hostelData.HostelInformationList.length === 0" class="info-not-found py-5">
          <p class="title">Information Not Found</p>
        </div>

        <!-- Show hostel info card if data is available -->
        <div v-else class="hostel-info-card-wrapper mt-4">
          <CRow class="mb-2" v-for="info in hostelData.HostelInformationList" :key="info.StudentIdFk">
            <CCol lg="4" xs="5">
              <div class="card-label">
                <p>Student Name:</p>
              </div>
            </CCol>
            <CCol lg="8" xs="7">
              <div class="info-title">
                <p>{{ info.StudentName }}</p>
              </div>
            </CCol>
          </CRow>

          <CRow class="mb-2" v-for="info in hostelData.HostelInformationList" :key="info.StudentIdFk">
            <CCol lg="4" xs="5">
              <div class="card-label">
                <p>Father Name:</p>
              </div>
            </CCol>
            <CCol lg="8" xs="7">
              <div class="info-title">
                <p>{{ info.FatherName }}</p>
              </div>
            </CCol>
          </CRow>

          <CRow class="mb-2" v-for="info in hostelData.HostelInformationList" :key="info.StudentIdFk">
            <CCol lg="4" xs="5">
              <div class="card-label">
                <p>Description:</p>
              </div>
            </CCol>
            <CCol lg="8" xs="7">
              <div class="info-title">
                <p>{{ info.Description }}</p>
              </div>
            </CCol>
          </CRow>

          <CRow class="mb-2" v-for="info in hostelData.HostelInformationList" :key="info.StudentIdFk">
            <CCol lg="4" xs="5">
              <div class="card-label">
                <p>Location Name:</p>
              </div>
            </CCol>
            <CCol lg="8" xs="7">
              <div class="info-title">
                <p>{{ info.LocationName }}</p>
              </div>
            </CCol>
          </CRow>

          <CRow class="mb-2" v-for="info in hostelData.HostelInformationList" :key="info.StudentIdFk">
            <CCol lg="4" xs="5">
              <div class="card-label">
                <p>Floor Name:</p>
              </div>
            </CCol>
            <CCol lg="8" xs="7">
              <div class="info-title">
                <p>{{ info.FloorName }}</p>
              </div>
            </CCol>
          </CRow>

          <CRow v-for="info in hostelData.HostelInformationList" :key="info.StudentIdFk">
            <CCol lg="4" xs="5">
              <div class="card-label">
                <p>Room Name:</p>
              </div>
            </CCol>
            <CCol lg="8" xs="7">
              <div class="info-title">
                <p>{{ info.RoomName }}</p>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </section>
</template>

<script>
import axios from "axios";
import { CRow, CCol } from "@coreui/vue";

export default {
  name: "hostel",
  data() {
    return {
      hostelData: {}, // Store hostel details
    };
  },

  mounted() {
    this.getHostelDetails(); // Fetch hostel details when component is mounted
  },

  methods: {
    async getHostelDetails() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const apiUrl = process.env.VUE_APP_API_URL;
      const mobileCode = process.env.VUE_APP_MOBILE_CODE;
      const notificationId = process.env.VUE_APP_NOTIFICATION_TYPE_ID;
      try {
        const model = {
          UserIdentity: token,
          StudentId: studentid,
          MobileCode: mobileCode,
          NotificationTypeId: notificationId,
        };
        const response = await axios.post(
          `${apiUrl}/GetHostelInformationByStudentId`,
          model
        );
        this.hostelData = response.data; // Assign the API response to hostelData
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
